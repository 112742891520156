import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import currencyFormatter from 'currency-formatter'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import Master from '../components/Master'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Chip from '../components/Chip'
import Button from '../ui/ButtonWhatsApp'
import Breadcrumbs from '../ui/CustomBreadcrumbs'

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  icon: {
    paddingRight: '5px'
  },
  images: {
    marginTop: '15px'
  },
  image: {
    width: '100%',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  mainImage: {
    width: '100%',
    borderRadius: '5px',
  },
  breadcrumbs: {
    color: 'rgba(0, 0, 0, 0.54)',
    textDecoration: 'none',
    '& a': {
      color: 'rgba(0, 0, 0, 0.54)',
      textDecoration: 'none',
    }
  },
  breadcrumb: {
    height: '30px'
  }
});

const Page = ({ data }) => {

  const classes = useStyles()

  const product = data.product
  const images = product.images;
  const imagesThumbnail = product.imagesThumbnail;

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const open = index => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  let categories = null
  if (data.product) {
    if (product.categories.length > 0) {
      categories = (
        <div>
          <div>
            <h3>categorias</h3>
          </div>
          <div className={classes.flex}>
            {product.categories.map(category => (
              <Chip key={category.id} link={`/categorias/${category.slug}`}>{category.title}</Chip>
            ))}
          </div>
        </div>
      )
    }
  }
  let keywords = null
  if (data.product) {
    if (product.keywords.length > 0) {
      keywords = (
        <div>
          <div>
            <h3>nuvem de tags</h3>
          </div>
          <div className={classes.flex}>
            {product.keywords.map(keyword => (
              <Chip key={keyword.id} link={`/tags/${keyword.slug}`}>{keyword.title}</Chip>
            ))}
          </div>
        </div>
      )
    }
  }

  let price = null
  if (data.product) {
    if (product.price > 0) {
      price = (
        <h4>
          {currencyFormatter.format(product.price, { locale: 'pt-BR' })}
        </h4>
      )
    }
  }

  return (
    <Master title={`${product.title} em Venda Nova - Belo Horizonte - MG`}>
      <GatsbySeo
        title={`${product.title} em Venda Nova - Belo Horizonte - MG`}
        description={product.description}
        canonical={`${process.env.SITE_URL}/produtos/${product.slug}`}
        noindex={false}
        nofollow={false}
        openGraph={{
          url: `${process.env.SITE_URL}/produtos/${product.slug}`,
          title: `${product.title} em Venda Nova - Belo Horizonte - MG`,
          description: product.description,
          images: [
            {
              url: product.image,
              width: 700,
              height: 700,
              alt: product.title
            }
          ],
          site_name: `${process.env.SITE_NAME}`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>{product.title}</h1>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <img src={product.imageThumbnail} title={`${product.title} em Venda Nova - Belo Horizonte - MG`} alt={`${product.title} em Venda Nova - Belo Horizonte - MG`} className={classes.mainImage} />
          <Grid container spacing={3} className={classes.images}>
            {imagesThumbnail.map((image, index) => (
              <Grid key={index} item xs={4} onClick={() => open(index)}>
                <img src={image} title={`${product.title} em Venda Nova - Belo Horizonte - MG`} alt={`${product.title} em Venda Nova - Belo Horizonte - MG`} className={classes.image} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          {price}
          <div dangerouslySetInnerHTML={{ __html: product.details }} />
          <p>
            <Button param={product.slug} attribute="produtos" ads="AW-354238672/NwUeCKyMtcICENCB9agB"><WhatsAppIcon className={classes.icon} /> Quero saber mais</Button>
          </p>
          {categories}
          {keywords}
          <div className={classes.breadcrumb}></div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs>
            <Link color="inherit" to='/' className={classes.breadcrumbs}>
              Home
            </Link>
            <Link color="inherit" to={`/produtos`} className={classes.breadcrumbs}>
              Produtos
            </Link>
            <Typography color="textPrimary">{product.title}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Master>
  )
}

export const query = graphql`
  query($slug: String!) {
    product(slug: {eq: $slug}) {
        title
        slug
        description        
        details
        image
        imageThumbnail
        images
        imagesThumbnail
        price
        categories {
          id
          title
          slug
        }
        keywords {
          id
          title
          slug
        }
      }
  }
`

export default Page